


 .left-logo img{
    background-color: red;
    margin-left: 0;
    margin: 0;
    padding: 5px;
    border-radius: 4px;
    font-size: 51px;
}

 .left-logo_2 img{
    background-color: blue;
    width: 107px;
    padding: 10px;
    border: 2px solid blue;
    border-radius: 1vh;
}



