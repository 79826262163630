.login-screen{
    background: url("../../assets/images/Skilllabs/background.jpg"), rgba(0, 0, 0, 0.43);
    background-size: cover;
}

.login-card{
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
}

.skill-btn{
    background-color: #1a3866 !important;
    border: none !important;
}

.register-link-text{
    color: #1a3866;
}

.register-link-text:hover{
    color: #f29440;
}