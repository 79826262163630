.profile-details-sec{
    text-align: center;
}

.profile-details-sec .student-img{
    padding-bottom: 10px;
}

.profile-details-sec .student-img img{
    width: 140px;
    border-radius: 15px;
}

.profile-details-sec h4{
    font-size: 19px;
    font-weight: 600;
}

.profile-details-sec h5{
    font-size: 17px;
}