.profile-sec{
    padding-bottom: 50px;
}

.user-main-sec{
    margin-top: 3%;
    margin-left: 20%;
    margin-right: 10%;
    margin-bottom: 20%;
    text-align: center;
}

.user-img{
    padding-bottom: 18px;
}

.user-img img{
    border-radius: 10px;
    width: 200px;
}

.user-name h4{
    font-size: 24px;
    color: black;
}

.user-email{
    padding-bottom: 10px;
}
.user-email h4{
    font-size: 18px;
}

.user-phn h4{
    font-size: 17px;
}

.education-details-heading{
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 2%;
}